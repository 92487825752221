
import {get,post} from "@/axios";

//订单分组
export function getGroupStatistics(data) {
    return get('/api/transactions/getGroupStatistics',data);
}

export function getPVIPWithGroups(data) {
    return get('/api/groups/getPVIPWithGroups',data);
}

