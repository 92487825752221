<template>
  <div>
    <!-- <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="分组名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="group_name">
            <a-input v-model="form.group_name" placeholder="请输入分组名称..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="客户邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-input v-model="form.email" placeholder="请输入客户邮箱..." />
          </a-form-model-item>
        </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="订单状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
              <a-select v-model="form.status" placeholder="请选择订单状态..." >
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="success">
                   成功
                </a-select-option>
                <a-select-option value="failed">
                   失败
                </a-select-option>
                <a-select-option value="unpaid">
                  未付款
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="支付方式" :labelCol='labelcol' :wrapperCol="wrappercol" prop="payment">
              <a-select v-model="form.payment" placeholder="请选择支付方式...">
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="paypal">
                  paypal
                </a-select-option>
                <a-select-option value="stripe">
                  stripe
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item style="text-align:right;">
              
              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div> -->
    <div class="mainContent" style="display: flex;justify-content: flex-start;">
      <a-button type="primary" @click="loadPv" :loading='isloadIp'>
        查看PV/IP
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.group_id"
        :data-source="data"
        :pagination="false"
        :loading="loading"
        bordered
      >
        
        <template slot="today_rate" slot-scope="data,record">{{parseInt(data*100)}}%</template>
        <template slot="yesterday_rate" slot-scope="data,record">{{parseInt(data*100)}}%</template>
        <template slot="before_yesterday_rate" slot-scope="data,record">{{parseInt(data*100)}}%</template>

        <template slot="today_pv" slot-scope="data,record"><span class='pv_span'>{{record.today.pv}}</span>/<span class='ip_span'>{{record.today.ip}}</span></template>
        <template slot="yesterday_pv" slot-scope="data,record"><span class='pv_span'>{{record.yesterday.pv}}</span>/<span class='ip_span'>{{record.yesterday.ip}}</span></template>
        <!-- 操作单元格 -->
        <!-- <template slot="操作" slot-scope="data">

              <span>

                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>
  
                <a-popconfirm v-if="data.status=='active'" title="是否禁用此分组?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">禁用</a-button type="primary" >
                </a-popconfirm>
                <a-popconfirm v-else title="是否启用此分组?" @confirm="() => onEnable(data)">
                  <a-button size="small" type="primary" class="greenBtn">启用</a-button type="primary">
                </a-popconfirm>

              </span>

        </template> -->

      </a-table>
    </div>


  </div>
</template>
<script>
import {getGroupStatistics,getPVIPWithGroups} from "@/axios/orderStatistics.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const todayColor='#f0f8fb';
const yesterdayColor='#fff4fd';
const before_yesterdayColor='#fffdf1';
const titleHeader=(color)=>{
  return{
    style: {
        // 行背景色
        'background-color':color,
        "font-weight":"bold"
    },
  }
}

const columns = [//列描述
  {
    align:'center',
    title: '分组名称',
    dataIndex: 'group_name',
  },
  // {
  //   align:'center',
  //   title: '分组状态',
  //   dataIndex: 'status',
  //   scopedSlots: { customRender: 'status', },
  // },
  {
    align:'center',
    title: '用户名',
    dataIndex: 'username',
  },
  {
    align:'center',
    title: '今日数据',
    customHeaderCell:()=>{//设置头部单元格属性
      return{
        style: {
            'background-color':todayColor,
            "font-weight":"bold"
        },
      }
    },
    children:[
      {
        align:'center',
        title: '订单成功数',
        dataIndex: 'today.success_total',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':todayColor,
            },
          }
        },
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.today.success_total - b.today.success_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单总数',
        dataIndex: 'today.order_total',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':todayColor,
            },
          }
        },
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.today.order_total - b.today.order_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单成功率',
        dataIndex: 'today.rate',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':todayColor,
            },
          }
        },
        sorter: (a, b) =>  {
          return b.username=='汇总'?'':a.today.rate - b.today.rate
        },
        sortDirections: ['descend',"ascend"],
        scopedSlots: { customRender: 'today_rate', },
      },
      {
        align:'center',
        title: 'PV/IP',
        dataIndex: 'today.pv',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':todayColor,
            },
          }
        },
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.today.ip - b.today.ip
        },
        sortDirections: ['descend',"ascend"],
        scopedSlots: { customRender: 'today_pv' },
      },
    ]
  },
  {
    align:'center',
    title: '昨日数据',
    customHeaderCell:()=>{//设置头部单元格属性
      return{
        style: {
            // 行背景色
            'background-color': yesterdayColor,
            "font-weight":"bold"
        },
      }
    },
    children:[
      {
        align:'center',
        title: '订单成功数',
        dataIndex: 'yesterday.success_total',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':yesterdayColor,
            },
          }
        },
        sorter: (a, b) =>{
          return b.username=='汇总'?'':a.yesterday.success_total - b.yesterday.success_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单总数',
        dataIndex: 'yesterday.order_total',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':yesterdayColor,
            },
          }
        },
        sorter: (a, b) =>{
          return b.username=='汇总'?'':a.yesterday.order_total - b.yesterday.order_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单成功率',
        dataIndex: 'yesterday.rate',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':yesterdayColor,
            },
          }
        },
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.yesterday.rate - b.yesterday.rate
        },
        sortDirections: ['descend',"ascend"],
        scopedSlots: { customRender: 'yesterday_rate', },
      },
      {
        align:'center',
        title: 'PV/IP',
        dataIndex: 'yesterday.pv',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':yesterdayColor,
            },
          }
        },
        sorter: (a, b) =>{
          return b.username=='汇总'?'':a.yesterday.ip - b.yesterday.ip
        },
        sortDirections: ['descend',"ascend"],
        scopedSlots: { customRender: 'yesterday_pv' },
      },
    ]
  },
  {
    align:'center',
    title: '前日数据',
    customHeaderCell:()=>{//设置头部单元格属性
      return{
        style: {
            // 行背景色
            'background-color': before_yesterdayColor,
            "font-weight":"bold"
        },
      }
    },
    children:[
      {
        align:'center',
        title: '订单成功数',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':before_yesterdayColor,
            },
          }
        },
        dataIndex: 'before_yesterday.success_total',
        sorter: (a, b) =>{
          return b.username=='汇总'?'':a.before_yesterday.success_total - b.before_yesterday.success_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单总数',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':before_yesterdayColor,
            },
          }
        },
        dataIndex: 'before_yesterday.order_total',
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.before_yesterday.order_total - b.before_yesterday.order_total
        },
        sortDirections: ['descend',"ascend"],
      },
      {
        align:'center',
        title: '订单成功率',
        customHeaderCell:()=>{
          return{
            style: {
                'background-color':before_yesterdayColor,
            },
          }
        },
        dataIndex: 'before_yesterday.rate',
        sorter: (a, b) => {
          return b.username=='汇总'?'':a.before_yesterday.rate - b.before_yesterday.rate
        },
        sortDirections: ['descend',"ascend"],
        scopedSlots: { customRender: 'before_yesterday_rate', },
      },
    ]
  },
  
  

  



  // {
  //   align:'center',
  //   title: '操作',
  //   // dataIndex: 'status',
  //   scopedSlots: { customRender: '操作', },
  // },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        active: '启用',
        deleted: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
 
    
      form: {
        domain:'',
        group_name: '',
        status: '',
        email:'',
        payment:'',
      },

      data: [],//数据
      selectedRowKeys: [],//选择的表格

      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'middle',//表格尺寸
      isloadIp:false,
    }
    
  },
  created(){
    let that=this
    this.fetch();

  },
  mounted() {
 
  },
  methods:{
      search(){//搜索
        this.fetch();
      },
      reset(formName){
        this.$refs[formName].resetFields();
        this.fetch()
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载1
        console.log("导出",this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },


      async fetch (params = {}) {
        this.loading = true;
        let data=await getGroupStatistics({page_size: 10,...params,...this.form})
        this.loading = false;
       
        this.data = data?.data;
        this.data=this.data.map((item)=>{
          item.today.pv=0;
          item.today.ip=0;
          item.yesterday.pv=0;
          item.yesterday.ip=0;
          return item
        })
      },
      async loadPv(){
        this.isloadIp=true
        let data=await getPVIPWithGroups()
        this.isloadIp=false

        data.data.map((item)=>{
          for(let i of this.data){       
            if(item.group_id==i.group_id){
              i.today.pv=item.today_pv
              i.today.ip=item.today_ip
              i.yesterday.pv=item.yesterday_pv
              i.yesterday.ip=item.yesterday_ip

              break;
            }
          }

        })
        this.data[0].today.ip=data.data.reduce((total, item) => {return total + Number(item.today_ip)},0)
        this.data[0].today.pv=data.data.reduce((total, item) => {return total + Number(item.today_pv)},0)
        this.data[0].yesterday.ip=data.data.reduce((total, item) => {return total + Number(item.yesterday_ip)},0)
        this.data[0].yesterday.pv=data.data.reduce((total, item) => {return total + Number(item.yesterday_pv)},0)
        this.data=[...this.data]

      }

    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.pv_span,.ip_span{
  padding:0 4px;
}
.pv_span{
  color:#d4811a
}
.ip_span{
  color:#119cab
}
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.ant-table td .spanItem{display: block;width:100%;text-align: left;}
</style>